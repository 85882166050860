













































import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'

@Component({
  components: {
    LayoutCompact
  }
})
export default class InquiryResult extends Vue {
  public status = this.$route.query.status || 'error'

  public gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
